import revive_payload_client_4sVQNw7RlN from "/root/injective/injective-helix/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/injective/injective-helix/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/injective/injective-helix/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/injective/injective-helix/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/root/injective/injective-helix/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/injective/injective-helix/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/root/injective/injective-helix/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/root/injective/injective-helix/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/injective/injective-helix/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/injective/injective-helix/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/root/injective/injective-helix/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/root/injective/injective-helix/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import buffer_s50p6ahcp9 from "/root/injective/injective-helix/node_modules/.c12/github_InjectiveLabs_injective_PTU18fwElc/nuxt-config/buffer.ts";
import tracking_3eSuOsLnlS from "/root/injective/injective-helix/node_modules/.c12/github_InjectiveLabs_injective_PTU18fwElc/plugins/tracking.ts";
import directives_8CcCirWtnE from "/root/injective/injective-helix/plugins/directives.ts";
import handlers_oZWLY9pUCB from "/root/injective/injective-helix/plugins/handlers.ts";
import hotjar_pBWYs5JQbk from "/root/injective/injective-helix/plugins/hotjar.ts";
import localstorage_Ek29vdFAiI from "/root/injective/injective-helix/plugins/localstorage.ts";
import store_9xNuDHGAVU from "/root/injective/injective-helix/plugins/store.ts";
import validation_30a3L3OexF from "/root/injective/injective-helix/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  buffer_s50p6ahcp9,
  tracking_3eSuOsLnlS,
  directives_8CcCirWtnE,
  handlers_oZWLY9pUCB,
  hotjar_pBWYs5JQbk,
  localstorage_Ek29vdFAiI,
  store_9xNuDHGAVU,
  validation_30a3L3OexF
]