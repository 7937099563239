import { default as fee_45discountsyNiZGjmTdbMeta } from "/root/injective/injective-helix/pages/fee-discounts.vue?macro=true";
import { default as _91slug_93xRkBM5dQBPMeta } from "/root/injective/injective-helix/pages/futures/[slug].vue?macro=true";
import { default as indexSk4tSMOYEAMeta } from "/root/injective/injective-helix/pages/futures/index.vue?macro=true";
import { default as futuresovabMDJGIKMeta } from "/root/injective/injective-helix/pages/futures.vue?macro=true";
import { default as _91guild_932E0gVDfzCtMeta } from "/root/injective/injective-helix/pages/guild/[guild].vue?macro=true";
import { default as guildsUJO24TyvxSMeta } from "/root/injective/injective-helix/pages/guilds.vue?macro=true";
import { default as indexafr8iNXDu5Meta } from "/root/injective/injective-helix/pages/index.vue?macro=true";
import { default as institutional9Ei8pa9WDHMeta } from "/root/injective/injective-helix/pages/institutional.vue?macro=true";
import { default as competitionAVRkYE4lmhMeta } from "/root/injective/injective-helix/pages/leaderboard/competition.vue?macro=true";
import { default as indexKpRHODW8XeMeta } from "/root/injective/injective-helix/pages/leaderboard/index.vue?macro=true";
import { default as leaderboard63CqDt6R5kMeta } from "/root/injective/injective-helix/pages/leaderboard.vue?macro=true";
import { default as liquidity_45provisionngoV5tSrrdMeta } from "/root/injective/injective-helix/pages/liquidity-provision.vue?macro=true";
import { default as campaignFRtPjquDPSMeta } from "/root/injective/injective-helix/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardlBWrUSsPeeMeta } from "/root/injective/injective-helix/pages/lp-rewards/dashboard.vue?macro=true";
import { default as index86uHwg7cxaMeta } from "/root/injective/injective-helix/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewardsZs7g30JjBRMeta } from "/root/injective/injective-helix/pages/lp-rewards.vue?macro=true";
import { default as maintenancejzbVwTGzdMMeta } from "/root/injective/injective-helix/pages/maintenance.vue?macro=true";
import { default as markets0qN8znMjD4Meta } from "/root/injective/injective-helix/pages/markets.vue?macro=true";
import { default as playgroundMICs7oinLbMeta } from "/root/injective/injective-helix/pages/playground.vue?macro=true";
import { default as indexbbntxz0mKeMeta } from "/root/injective/injective-helix/pages/portfolio/balances/index.vue?macro=true";
import { default as indexa6aqIYjnKxMeta } from "/root/injective/injective-helix/pages/portfolio/history/funding-payments/index.vue?macro=true";
import { default as indexboVUqmxGBgMeta } from "/root/injective/injective-helix/pages/portfolio/history/swap/index.vue?macro=true";
import { default as indexZcvJMr1SszMeta } from "/root/injective/injective-helix/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as index5uqvSMXQVCMeta } from "/root/injective/injective-helix/pages/portfolio/index.vue?macro=true";
import { default as futures_45gridbRnmF1e6Y0Meta } from "/root/injective/injective-helix/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as indexw8dKlXAWQLMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historyJlFe2VYKd8Meta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historymcDtu9ZgzIMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as triggersCNCCkLWLwRMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/triggers.vue?macro=true";
import { default as futuresjQZah79EbpMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures.vue?macro=true";
import { default as history7yWrS3e7LSMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid/history.vue?macro=true";
import { default as indexonwAMWoTirMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid/index.vue?macro=true";
import { default as spot_45gridYo9tecYuzqMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as indexhFFKasXH6aMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historyrmpFwHgVrWMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45historyzwS9ePHeP2Meta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spotIrpY2ruzv1Meta } from "/root/injective/injective-helix/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexNOFtGKlPEcMeta } from "/root/injective/injective-helix/pages/portfolio/positions/index.vue?macro=true";
import { default as granterDkd1GdhmzTMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexovhNaqBomCMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzlYcJImBSKtMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignGu9wEwX2Q3Meta } from "/root/injective/injective-helix/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexhZRGgAgF6ZMeta } from "/root/injective/injective-helix/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencesyYHgUidP7SMeta } from "/root/injective/injective-helix/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexrTffH0fnuuMeta } from "/root/injective/injective-helix/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolio1iR5JDOmuXMeta } from "/root/injective/injective-helix/pages/portfolio.vue?macro=true";
import { default as _91slug_93m5s37au3K8Meta } from "/root/injective/injective-helix/pages/spot/[slug].vue?macro=true";
import { default as indexYF9WYnx5GxMeta } from "/root/injective/injective-helix/pages/spot/index.vue?macro=true";
import { default as spotW1MXLxNeOAMeta } from "/root/injective/injective-helix/pages/spot.vue?macro=true";
import { default as swapZVLi3jyaaaMeta } from "/root/injective/injective-helix/pages/swap.vue?macro=true";
import { default as termsiNnhQngnD9Meta } from "/root/injective/injective-helix/pages/terms.vue?macro=true";
import { default as trade_45and_45earnE5uk6DptgWMeta } from "/root/injective/injective-helix/pages/trade-and-earn.vue?macro=true";
import { default as historyVTZI8JDwtBMeta } from "/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/history.vue?macro=true";
import { default as indexYmfueowi6tMeta } from "/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as liquidity_45botsF8275bLGcNMeta } from "/root/injective/injective-helix/pages/trading-bots/liquidity-bots.vue?macro=true";
export default [
  {
    name: "fee-discounts",
    path: "/fee-discounts",
    component: () => import("/root/injective/injective-helix/pages/fee-discounts.vue").then(m => m.default || m)
  },
  {
    name: futuresovabMDJGIKMeta?.name,
    path: "/futures",
    component: () => import("/root/injective/injective-helix/pages/futures.vue").then(m => m.default || m),
    children: [
  {
    name: "futures-slug",
    path: ":slug()",
    meta: _91slug_93xRkBM5dQBPMeta || {},
    component: () => import("/root/injective/injective-helix/pages/futures/[slug].vue").then(m => m.default || m)
  },
  {
    name: "futures",
    path: "",
    meta: indexSk4tSMOYEAMeta || {},
    component: () => import("/root/injective/injective-helix/pages/futures/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "guild-guild",
    path: "/guild/:guild()",
    component: () => import("/root/injective/injective-helix/pages/guild/[guild].vue").then(m => m.default || m)
  },
  {
    name: "guilds",
    path: "/guilds",
    component: () => import("/root/injective/injective-helix/pages/guilds.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/injective/injective-helix/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institutional",
    path: "/institutional",
    meta: institutional9Ei8pa9WDHMeta || {},
    component: () => import("/root/injective/injective-helix/pages/institutional.vue").then(m => m.default || m)
  },
  {
    name: leaderboard63CqDt6R5kMeta?.name,
    path: "/leaderboard",
    meta: leaderboard63CqDt6R5kMeta || {},
    component: () => import("/root/injective/injective-helix/pages/leaderboard.vue").then(m => m.default || m),
    children: [
  {
    name: "leaderboard-competition",
    path: "competition",
    component: () => import("/root/injective/injective-helix/pages/leaderboard/competition.vue").then(m => m.default || m)
  },
  {
    name: "leaderboard",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/leaderboard/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "liquidity-provision",
    path: "/liquidity-provision",
    component: () => import("/root/injective/injective-helix/pages/liquidity-provision.vue").then(m => m.default || m)
  },
  {
    name: lp_45rewardsZs7g30JjBRMeta?.name,
    path: "/lp-rewards",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards.vue").then(m => m.default || m),
    children: [
  {
    name: "lp-rewards-campaign",
    path: "campaign",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/campaign.vue").then(m => m.default || m)
  },
  {
    name: "lp-rewards-dashboard",
    path: "dashboard",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "lp-rewards",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancejzbVwTGzdMMeta || {},
    component: () => import("/root/injective/injective-helix/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/root/injective/injective-helix/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    meta: playgroundMICs7oinLbMeta || {},
    component: () => import("/root/injective/injective-helix/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: portfolio1iR5JDOmuXMeta?.name,
    path: "/portfolio",
    meta: portfolio1iR5JDOmuXMeta || {},
    component: () => import("/root/injective/injective-helix/pages/portfolio.vue").then(m => m.default || m),
    children: [
  {
    name: "portfolio-balances",
    path: "balances",
    component: () => import("/root/injective/injective-helix/pages/portfolio/balances/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-history-funding-payments",
    path: "history/funding-payments",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/funding-payments/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-history-swap",
    path: "history/swap",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/swap/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-history-wallet",
    path: "history/wallet",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-futures-grid",
    path: "orders/futures-grid",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures-grid.vue").then(m => m.default || m)
  },
  {
    name: futuresjQZah79EbpMeta?.name,
    path: "orders/futures",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures.vue").then(m => m.default || m),
    children: [
  {
    name: "portfolio-orders-futures",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-futures-order-history",
    path: "order-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/order-history.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-futures-trade-history",
    path: "trade-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/trade-history.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-futures-triggers",
    path: "triggers",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/triggers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spot_45gridYo9tecYuzqMeta?.name,
    path: "orders/spot-grid",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid.vue").then(m => m.default || m),
    children: [
  {
    name: "portfolio-orders-spot-grid-history",
    path: "history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid/history.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-spot-grid",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spotIrpY2ruzv1Meta?.name,
    path: "orders/spot",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot.vue").then(m => m.default || m),
    children: [
  {
    name: "portfolio-orders-spot",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-spot-order-history",
    path: "order-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/order-history.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-orders-spot-trade-history",
    path: "trade-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/trade-history.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portfolio-positions",
    path: "positions",
    component: () => import("/root/injective/injective-helix/pages/portfolio/positions/index.vue").then(m => m.default || m)
  },
  {
    name: authzlYcJImBSKtMeta?.name,
    path: "settings/authz",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz.vue").then(m => m.default || m),
    children: [
  {
    name: "portfolio-settings-authz-granter",
    path: "granter",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz/granter.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-settings-authz",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portfolio-settings-autosign",
    path: "settings/autosign",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/autosign.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-settings",
    path: "settings",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-settings-preferences",
    path: "settings/preferences",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/preferences.vue").then(m => m.default || m)
  },
  {
    name: "portfolio-subaccounts",
    path: "subaccounts",
    component: () => import("/root/injective/injective-helix/pages/portfolio/subaccounts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spotW1MXLxNeOAMeta?.name,
    path: "/spot",
    component: () => import("/root/injective/injective-helix/pages/spot.vue").then(m => m.default || m),
    children: [
  {
    name: "spot-slug",
    path: ":slug()",
    meta: _91slug_93m5s37au3K8Meta || {},
    component: () => import("/root/injective/injective-helix/pages/spot/[slug].vue").then(m => m.default || m)
  },
  {
    name: "spot",
    path: "",
    meta: indexYF9WYnx5GxMeta || {},
    component: () => import("/root/injective/injective-helix/pages/spot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "swap",
    path: "/swap",
    meta: swapZVLi3jyaaaMeta || {},
    component: () => import("/root/injective/injective-helix/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/root/injective/injective-helix/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "trade-and-earn",
    path: "/trade-and-earn",
    component: () => import("/root/injective/injective-helix/pages/trade-and-earn.vue").then(m => m.default || m)
  },
  {
    name: "trading-bots-liquidity-bots",
    path: "/trading-bots/liquidity-bots",
    component: () => import("/root/injective/injective-helix/pages/trading-bots/liquidity-bots.vue").then(m => m.default || m),
    children: [
  {
    name: "trading-bots-liquidity-bots-spot-history",
    path: "spot/history",
    component: () => import("/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/history.vue").then(m => m.default || m)
  },
  {
    name: "trading-bots-liquidity-bots-spot",
    path: "spot",
    component: () => import("/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue").then(m => m.default || m)
  }
]
  }
]